import {memo} from 'react'

import HamburgerMenu from './HamburgerMenu'
import Profile from './Profile'
import Badge from './Badge'
import LogoContainer from './Logo'

function SideNav() {
  return (
    <div id='header-container'>
      <header id='side-nav-container'>
        <HamburgerMenu />
        <LogoContainer />
        <Badge />
        <Profile />
      </header>
    </div>
  )
}

export default memo(SideNav)
