import i18next from 'i18next'
import { DropdownIndicator } from '../../../../../../helpers/react-select-backyard-styles'
import Select from 'react-select'
import BusinessUnitType from '../../../../../../enums/BusinessUnitType'

type BuCdSelectType = {
  onChange: Function
  value: number
}

function BuCdSelect(props: BuCdSelectType) {
  const { onChange, value } = props

  const label = i18next.t('user.changeBuCd.select.label')

  const selectOptions = [
    { value: BusinessUnitType.LMPT.buCd, label: i18next.t(BusinessUnitType.LMPT.translation) },
    { value: BusinessUnitType.LMBR.buCd, label: i18next.t(BusinessUnitType.LMBR.translation) },
    { value: BusinessUnitType.LMES.buCd, label: i18next.t(BusinessUnitType.LMES.translation) },
    { value: BusinessUnitType.LMFR.buCd, label: i18next.t(BusinessUnitType.LMFR.translation) },
    { value: BusinessUnitType.LMIT.buCd, label: i18next.t(BusinessUnitType.LMIT.translation) },
  ]

  return (
    <div className='justify-input'>
      <label>{label}</label>

      <Select
        value={selectOptions.filter((option) => option.value === value)[0]}
        options={selectOptions}
        onChange={(e: any) => onChange(e)}
        isOptionDisabled={(option: any) => option.disabled}
        components={{ DropdownIndicator }}
        classNamePrefix='react-select'
        menuPosition='fixed'
      />
    </div>
  )
}

export default BuCdSelect
