import { useEffect, useMemo, useState } from 'react'
import i18n from 'i18next'
import { Flex, Button, useNotifications } from '@mozaic-ds/react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import Filter, { FilterValues } from './components/Filter'
import Table from './components/Table'
import InstallersListContext from './context'
import { buildUrlFilter, getUrlFilters, getUrlPage } from './utils/FilterQueryParamsUtils'
import {
  fetchInstallersList,
  Installer,
  FetchInstallersFilter,
} from '../../services/installers/fetchInstallersList'
import { removeMask } from '../../helpers/masks'
import { fetchAsyncReportsProvider } from '../../services/Reports'
import { ADEOResponseError } from '../../models/AdeoResponse'
import User from '../../core/User'

import './index.scss'
import { Page } from '../../components/Page'

const user = new User()

const ACCESS_FUNCTION_EDIT_INSTALLERS = 21

/**
 * installers list page
 */
function InstallersList() {
  const [page, setPage] = useState<number>()
  const [pages, setPages] = useState(1)
  const [total, setTotal] = useState(0)
  const [filter, setFilter] = useState<FilterValues>({
    q: '',
    active: '',
    receivingOrder: '',
    stores: [],
  })
  const [isLoading, setIsLoading] = useState(false)
  const [installers, setInstallers] = useState<Installer[]>([])
  const history = useHistory()
  const notification = useNotifications()

  const hasUserAccessToEdit =
    user.currentUser.accessFunctionList?.indexOf(ACCESS_FUNCTION_EDIT_INSTALLERS) !== -1

  const removeDocumentMask = (value: string) => {
    if (!value.match(/[a-zA-Z\u00C0-\u00FF ]+/i)) {
      return removeMask(value)
    }
    return value
  }

  const getApiValues = (): FetchInstallersFilter => ({
    dynamicSearch: removeDocumentMask(filter.q),
    actvServcPrvdrAgntInd: filter.active,
    receivingServiceOrder: filter.receivingOrder,
    lsPlntCd: filter.stores,
  })

  const onExport = () => {
    const values: any = {
      tpReport: 2,
      filters: getApiValues(),
    }

    fetchAsyncReportsProvider(user.currentUser?.dsToken, values)
  }

  useEffect(() => {
    if (!page) return
    setIsLoading(true)

    fetchInstallersList(page, getApiValues())
      .then((response) => {
        history.replace(buildUrlFilter(history.location.pathname, page, filter))
        setInstallers(response.data || [])
        setPages(response.metadata?.pageCount || 1)
        setTotal(response.metadata?.totalCount || 0)
      })
      .catch((error: ADEOResponseError) => {
        setInstallers([])
        if (error?.cdStatus === 401) return

        console.error('error fetching installers', error)

        notification.danger(
          {
            title: i18n.t('pwa.toast.error.message'),
            message: error?.message?.dsMessage || i18n.t('provider.portal.people.installers.error'),
            duration: 8000,
          },
          'top',
        )
      })
      .finally(() => setIsLoading(false))
  }, [page, filter])

  useEffect(() => {
    setFilter(getUrlFilters())
    setPage(getUrlPage())
  }, [])

  const contextValues = useMemo(
    () => ({
      setInstallers,
      installers,
      page: page || 1,
      total,
      setPage,
      pages,
      isLoading,
      filter,
      setFilter,
      onExport,
    }),
    [
      setInstallers,
      installers,
      page,
      setPage,
      pages,
      total,
      isLoading,
      filter,
      setFilter,
      onExport,
    ],
  )

  return (
    <Page id='installers-list' title='installers.list.title'>
      {hasUserAccessToEdit && (
        <Flex justifyContent='flex-end' alignItems='center'>
          <Link
            to={{
              pathname: '/installers/new',
              state: { from: `/installers${history.location.search}` },
            }}
          >
            <Button>{i18n.t('installers.list.add')}</Button>
          </Link>
        </Flex>
      )}
      <InstallersListContext.Provider value={contextValues}>
        <Filter />
        <Table />
      </InstallersListContext.Provider>
    </Page>
  )
}

export default InstallersList
