import { Grid } from '../../../../../leroy-merlin-br/lm-instala-components'
import { Button, Field, TextInput } from '@mozaic-ds/react'
import i18next from 'i18next'
import queryString from 'query-string'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ValueType } from 'react-select'
import Mask from 'vanilla-masker'
import MozaicReactSelect, {
  MozaicReactSelectOption,
} from '../../../../components/MozaicReactSelect'
import { StoresSelectV2 } from '../../../../components/StoresSelectV2'
import { SERVICE_PROVIDER_REGISTRATION_STATUS_OPTIONS } from '../../../../constants/pages/ServiceProviderList'
import User from '../../../../core/User'
import { fetchAsyncReportsProvider } from '../../../../services/Reports'
import { useServiceProvidersListV2Context } from '../../contexts'

const user = new User()

export interface FiltersForm {
  q: string
  natrlPrsnFedrlTaxIdNr: string
  actvServcPrvdrInd: '0' | '1' | '2'
  lsServcPrvdrMainPlntCd: string[]
  receivingServiceOrder: 0 | 1 | 2
}

/**
 * @description Filters component for Service Providers List V2 page
 */

export function Filters() {
  const form = useForm()
  const {
    handleFetchServiceProviders,
    isFetchServiceProvidersLoading,
    isFetchServiceProvidersError,
    filters,
  } = useServiceProvidersListV2Context()

  const actvServcPrvdrIndOptions = [
    { label: i18next.t('option.active'), value: '1' },
    { label: i18next.t('option.inactive'), value: '0' },
  ]

  const receivingServiceOrderOptions = [
    { label: i18next.t('option.active'), value: 1 },
    { label: i18next.t('option.inactive'), value: 0 },
  ]

  const getURLSearchParams = () => {
    const searchParams = queryString.parse(window.location.search, {
      arrayFormat: 'comma',
    })

    return searchParams
  }

  const getURLSearchArrayParams = (param: string) => {
    const searchParams = getURLSearchParams()

    if (Array.isArray(searchParams[param])) {
      return searchParams[param]
    }

    if (searchParams[param]) {
      return [searchParams[param]]
    }

    return []
  }

  useEffect(() => {
    const searchParams = getURLSearchParams()

    form.setValue('q', searchParams.q || '')
    form.setValue('natrlPrsnFedrlTaxIdNr', searchParams.natrlPrsnFedrlTaxIdNr || '')
    form.setValue('actvServcPrvdrInd', searchParams.actvServcPrvdrInd || '2')
    form.setValue('lsServcPrvdrMainPlntCd', getURLSearchArrayParams('lsServcPrvdrMainPlntCd'))
    form.setValue(
      'receivingServiceOrder',
      searchParams.receivingServiceOrder === '0'
        ? 0
        : Number(searchParams.receivingServiceOrder) || 2,
    )

    handleFetchServiceProviders({
      fields: {
        ...(form.getValues() as FiltersForm),
      },
      page: Number(searchParams.page) || 1,
    })
  }, [])

  const handleOnChangeReceivingServiceOrder = (
    selectedOption: ValueType<MozaicReactSelectOption, boolean>,
  ) => {
    if (!selectedOption) return form.setValue('receivingServiceOrder', 2)
    form.setValue('receivingServiceOrder', (selectedOption as MozaicReactSelectOption).value)
  }

  const onSubmit = (data: any) => {
    const filters: FiltersForm = {
      ...data,
      lsServcPrvdrMainPlntCd: form.getValues('lsServcPrvdrMainPlntCd'),
    }
    handleFetchServiceProviders({ fields: filters, page: 1 })
  }

  const getCSV = () => {
    const formattedFilters = {
      ...filters,
      genericSearch: filters.q,
      requestOriginPortal: 'SERVICES',
    }

    delete formattedFilters.q

    fetchAsyncReportsProvider(user.currentUser.dsToken, {
      tpReport: 3,
      filters: formattedFilters,
    })
  }

  return (
    <form data-testid='service-providers-list-v2-filters' onSubmit={form.handleSubmit(onSubmit)}>
      <Grid.Row rowGap='100' alignItems='flex-start'>
        <Grid.Col sizeXl='3' sizeMd='4'>
          <Field label={i18next.t('serviceProvider.genericSearch')} htmlFor='q' style={{ flex: 1 }}>
            <TextInput
              id='q'
              placeholder={i18next.t('serviceProvider.genericSearch')}
              size='m'
              defaultValue=''
              {...form.register('q')}
            />
          </Field>
        </Grid.Col>
        <Grid.Col sizeXl='2' sizeMd='4'>
          <Field
            label={i18next.t('field.name.person.main.document')}
            htmlFor='natrlPrsnFedrlTaxIdNr'
            style={{ flex: 1 }}
          >
            <TextInput
              id='natrlPrsnFedrlTaxIdNr'
              placeholder={i18next.t('field.name.person.main.document')}
              size='m'
              defaultValue=''
              {...form.register('natrlPrsnFedrlTaxIdNr', {
                onChange: (e) => {
                  form.setValue(
                    'natrlPrsnFedrlTaxIdNr',
                    Mask.toPattern(e.target.value, i18next.t('cpf.mask') ?? undefined),
                  )
                },
                value: form.getValues('natrlPrsnFedrlTaxIdNr'),
              })}
            />
          </Field>
        </Grid.Col>
        <Grid.Col sizeXl='2' sizeMd='4'>
          <Field
            label={i18next.t('serviceProvider.prestadorServicoAtivo')}
            htmlFor='actvServcPrvdrInd'
            style={{ flex: 1 }}
          >
            <Controller
              name='actvServcPrvdrInd'
              control={form.control}
              defaultValue='2'
              render={({ field }) => (
                <MozaicReactSelect
                  inputId='actvServcPrvdrInd'
                  name='actvServcPrvdrInd'
                  inputProps={{ name: field.name }}
                  options={actvServcPrvdrIndOptions}
                  onChange={(e: any) => field.onChange(e?.value || '2')}
                  placeholder={i18next.t('option.all')}
                  value={actvServcPrvdrIndOptions.find((c) => c.value === field.value)}
                  className='active-select-filter'
                />
              )}
            />
          </Field>
        </Grid.Col>
        <Grid.Col sizeXl='3' sizeMd='4'>
          <Field
            label={i18next.t('serviceProvider.servcPrvdrMainPlntCd')}
            htmlFor='lsServcPrvdrMainPlntCd'
            style={{ flex: 1 }}
          >
            <Controller
              name='lsServcPrvdrMainPlntCd'
              control={form.control}
              defaultValue={[]}
              render={({ field }) => (
                <StoresSelectV2
                  inputId='lsServcPrvdrMainPlntCd'
                  inputProps={{ name: field.name }}
                  onChange={(e: any) => {
                    field.onChange((e || []).map((item: any) => item.value))
                  }}
                  value={field.value}
                  isMulti
                  closeMenuOnSelect={false}
                  className='stores-select-filter'
                />
              )}
            />
          </Field>
        </Grid.Col>
        <Grid.Col sizeXl='2' sizeMd='4'>
          <Field
            label={i18next.t('serviceProvider.receivingServiceOrder')}
            htmlFor='receivingServiceOrder'
            style={{ flex: 1 }}
          >
            <Controller
              name='receivingServiceOrder'
              control={form.control}
              defaultValue={2}
              render={({ field }) => (
                <MozaicReactSelect
                  inputId='receivingServiceOrder'
                  inputProps={{ name: field.name }}
                  options={receivingServiceOrderOptions}
                  onChange={handleOnChangeReceivingServiceOrder}
                  placeholder={i18next.t('option.all')}
                  value={receivingServiceOrderOptions.find((c) => c.value === field.value)}
                  className='receiving-order-select-filter'
                />
              )}
            />
          </Field>
        </Grid.Col>
        <Grid.Col sizeXl='8' sizeMd='12'>
          <div />
        </Grid.Col>
        <Grid.Col alignItems='flex-end' sizeXl='2' sizeMd='2' size='6'>
          <Button
            id='filter-button'
            width='full'
            type='submit'
            style={{
              marginTop: '22px',
            }}
            isDisabled={isFetchServiceProvidersLoading}
          >
            {i18next.t('filter_button_label')}
          </Button>
        </Grid.Col>
        <Grid.Col alignItems='flex-end' sizeXl='2' sizeMd='2' size='6'>
          <Button
            id='export-button'
            variant='bordered'
            width='full'
            style={{
              marginTop: '22px',
            }}
            isDisabled={isFetchServiceProvidersLoading || isFetchServiceProvidersError}
            onClick={() => {
              getCSV()
            }}
          >
            {i18next.t('option.export')}
          </Button>
        </Grid.Col>
      </Grid.Row>
    </form>
  )
}
