import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import PingOneRedirect from '../PingOne'
import FeedbackError from '../../components/utils/FeedbackError'
import { loginUser } from '../../redux/actions/auth/loginUser'
import { isEmpty } from '../../helpers/validations'

import { UPDATE_CURRENT_ROUTE } from '../../redux/actionTypes/menu'
import { LoginForm } from './components/Form'

import store from '../../redux/store'
import logo from '../../assets/icons/logo.svg'

class LoginContainer extends Component {
  componentDidMount() {
    store.dispatch({ type: UPDATE_CURRENT_ROUTE, payload: '/Login' })
  }

  renderError(message) {
    const { t } = this.props

    if (message.dsMessage) {
      return (
        <FeedbackError message={t(message?.dsMessage)} text={t('useraccess.login.try_again')} />
      )
    }
  }

  render() {
    const { t, error, auth, loginUser, redirectUrl } = this.props

    const pingoneEnable = auth?.setupParameters?.pingoneEnable
    const currentBase = auth?.setupParameters?.currentBase
    const isToShowEnvironmentBadge = currentBase !== 'PROD' && !isEmpty(currentBase)

    return (
      <>
        {pingoneEnable && <PingOneRedirect />}
        {!pingoneEnable && (
          <section id='container-login'>
            <div className='box'>
              <section className='login-area'>
                <div className='logo-container-login'>
                  <img className='image' src={logo} alt='' />

                  <span className='portal-name'>{t('app.name.services-portal')}</span>

                  {isToShowEnvironmentBadge && (
                    <span className='badge-current-base'>{auth.setupParameters.currentBase}</span>
                  )}
                </div>

                {error && this.renderError(error.message)}

                <LoginForm
                  t={t}
                  error={error}
                  loginUser={(values) => loginUser(values, redirectUrl)}
                />
              </section>
            </div>
          </section>
        )}
      </>
    )
  }
}

LoginContainer.propTypes = {
  loginUser: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  error: PropTypes.object,
  auth: PropTypes.object.isRequired,
  redirectUrl: PropTypes.string,
}

/**
 * Mapeia state para props
 * @param {Object} state - estado atual
 * @return {Object} props
 */
/* istanbul ignore next */
const mapStateToProps = ({ auth }) => ({
  auth,
  error: auth.error,
  redirectUrl: auth.redirectUrl,
})

/**
 * Mapeia actions e o dispath para props
 * @param {Function} dispatch
 * @return {Object} props
 */
/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => bindActionCreators({ loginUser }, dispatch)

const LoginContainerTranslated = withTranslation()(LoginContainer)

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainerTranslated)

export { LoginContainer }
