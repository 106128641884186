import API_URL from '../../../core/Envs/endpoints'
import ApiRequest from '../../../core/Http/FetchAdapter'

import { onFetchUserError, onFetchUserSuccess, requestLogin } from '.'

export const getUserInfo = (token) => (dispatch) => {
  dispatch(requestLogin())

  return new ApiRequest(token, dispatch).get(API_URL.GET_USER_INFO).then((response) => {
    if (response.cdStatus !== 200) onFetchUserError('TypeError: Fail to fetch', dispatch)

    const path = localStorage?.getItem('lastRouteAccessed') || '/home'
    onFetchUserSuccess(response, dispatch, path)
  })
  // Removed because a error when response header 'Access-Control-Allow-Origin' and the origin of request won't match.
  // .catch((error) => onFetchUserError(error, dispatch))
}
