import i18next from 'i18next'
import _, { isEqual } from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { Component, createRef } from 'react'
import { Link } from 'react-router-dom'
import MozaicHeading from '../../../components/MozaicHeading'
import MozaicPasswordInput from '../../../components/MozaicPasswordInput'
import MozaicText from '../../../components/MozaicText'
import StatesSelect from '../../../components/StatesSelect'
import Col from '../../../components/utils/Col'
import Field from '../../../components/utils/Field'
import Form from '../../../components/utils/Form'
import Input from '../../../components/utils/Input'
import InputButton from '../../../components/utils/InputButton'
import Notification from '../../../components/utils/Notification'
import Row from '../../../components/utils/Row'
import TelephoneInput from '../../../components/utils/internationalPhoneInput/phoneInput'
import { FORMAT_DATE, FORMAT_SINGLE_DATE_PICKER } from '../../../constants/config'
import { getPhoneNumberCountry } from '../../../helpers/InternationalPhoneNumberUtils'
import { renderToastSuccessMsg } from '../../../helpers/ToastUtils'
import { getCPFMask, removeMask } from '../../../helpers/masks'
import { isEmpty } from '../../../helpers/validations'
import { hideSpinner, showSpinner } from '../../../redux/actions/spinner'
import store from '../../../redux/store'
import { updateProviderSpecialistSpecialitiesWithPriority } from '../../../services/pages/ServiceSpecialistEdit/SpecialistSpecialities'
import ServiceProviderSelect from './ServiceProviderSelect'
import ProfileSelect from './ProfileSelect'
import BranchSelectMultiple from './ProviderBranchSelectMultiple'
import SpecialitiesSelect from './SpecialitiesSelectMultiple'
import Table from './servcPrvdrAgntAbsncPerd/Table'

class ServiceSpecialistEditForm extends Component {
  constructor(props) {
    super(props)
    this.SpecialitiesSelectP1Ref = createRef()
    this.SpecialitiesSelectP2Ref = createRef()
  }

  state = {
    lsPhones: [{ valMeioComunicacao: '' }],
    errors: {},
    lsBranchesJustification: null,
    lsSpecialistActive: true,
    changeServcPrvdrAgntAbsncPerds: [],
    actvServcPrvdrAgnt: '0',
    didSetInitials: false,
  }

  refChildBranchSelect = null

  // eslint-disable-next-line
  userRegex = new RegExp('^[a-z0-9W@._]+$', 'i')

  componentDidUpdate(prevProps) {
    const { initialValues, setValueToField, fields, values } = this.props

    const hasSpecialistId = !!initialValues.idEspecialistaServico
    const isSpecialistIdChanged =
      initialValues.idEspecialistaServico !== prevProps.initialValues.idEspecialistaServico
    const isSpecialistUserCdChanged =
      initialValues.codUserEspecialistaServico !==
      prevProps.initialValues.codUserEspecialistaServico

    if (hasSpecialistId && (isSpecialistIdChanged || isSpecialistUserCdChanged)) {
      Object.entries(fields).map(([key, value]) => (values[key] = value.input.value))
      Object.entries(fields).forEach(([key, value]) => {
        // eslint-disable-next-line no-param-reassign
        value.input.value = initialValues[key]
        // eslint-disable-next-line no-param-reassign
        if (initialValues[key]) value.touched = true
      })
      this.setState({
        lsPhones: [
          { valMeioComunicacao: values.phone1 },
          { valMeioComunicacao: values.phone2 },
          { valMeioComunicacao: values.phone2 },
        ],
      })

      if (initialValues) {
        this.handleServiceProviderAutocomplete(initialValues.nomePrestadorServico)
        setValueToField('nomePrestadorServico', initialValues.nomePrestadorServico)
        setValueToField('idPrestadorServico', initialValues.idPrestadorServico)
        setValueToField(
          'datUltimaSincronizacao',
          moment(initialValues.datUltimaSincronizacao).format(FORMAT_DATE),
        )
        setValueToField('phone1', initialValues.phone1 || '')

        this.setState({ lsPhones: initialValues.lsPhones })
        this.setState({
          lsSpecialistActive: initialValues.indEspecialistaServicoAtivo,
          actvServcPrvdrAgnt: initialValues.indEspecialistaServicoAtivo,
        })
      }
    }
  }

  get fields() {
    const { fields } = this.props
    return fields
  }

  childBranchSelect = (element) => {
    this.refChildBranchSelect = element
  }

  handleSelectChange = (selected, name) => {
    // eslint-disable-next-line no-param-reassign
    selected = selected || { value: null }
    const { value } = selected
    const { setValueToField, resetError } = this.props

    resetError(name)
    setValueToField(name, value)
  }

  handleChange = (event) => {
    const { name, value } = event.target
    const { fields, setValueToField, initialValues, resetError } = this.props
    const { actvServcPrvdrAgnt } = this.state

    resetError(name)
    setValueToField(name, value)
    fields[name].input.onChange(event)

    if (!initialValues.servcPrvdrAgntId) {
      if (name === 'datNascimento') {
        if (value.length >= 10) {
          const birthDate = value.split('/')
          const temporaryPassword = birthDate[1] + birthDate[2]
          setValueToField('descSenhaEspecialistaServico', temporaryPassword)
          setValueToField('descSenhaEspecialistaServicoConfirm', temporaryPassword)
        } else {
          setValueToField('descSenhaEspecialistaServico', '')
          setValueToField('descSenhaEspecialistaServicoConfirm', '')
        }
      }

      if (name === 'numCpfEspecialistaServico') {
        setValueToField('codUserEspecialistaServico', value ? removeMask(value) : '')
      }
    }

    if (name === 'indEspecialistaServicoAtivo') {
      const actvServcPrvdrAgntStatus = actvServcPrvdrAgnt === '1' ? '0' : '1'
      this.setState({ actvServcPrvdrAgnt: actvServcPrvdrAgntStatus })
    }
  }

  handlePhoneNumberChange = (fieldName, value) => {
    const { lsPhones } = this.state
    const { setValueToField, resetError } = this.props

    switch (fieldName) {
      case 'phone1':
        lsPhones[0].valMeioComunicacao = value
        break

      case 'phone2':
        lsPhones[1].valMeioComunicacao = value
        break

      case 'phone3':
        lsPhones[2].valMeioComunicacao = value
        break
    }

    this.setState({ lsPhones })

    resetError(fieldName)
    setValueToField(fieldName, value)
    this.fields[fieldName].input.onChange({
      target: {
        name: fieldName,
        value,
      },
    })
  }

  diffList = (l1 = [], l2 = []) =>
    l1.some((e1) => !l2.some((e2) => isEqual(e2, e1))) ||
    l2.some((e1) => !l1.some((e2) => isEqual(e2, e1)))

  handleBeforeSubmit = (event) => {
    const {
      auth: {
        user: { dsToken },
      },
      initialValues,
      values,
      fields,
    } = this.props

    Object.entries(fields).map(([key, value]) => (values[key] = value.input.value))
    this.setState({
      lsPhones: [
        { valMeioComunicacao: values.phone1 },
        { valMeioComunicacao: values.phone2 },
        { valMeioComunicacao: values.phone2 },
      ],
    })

    event.preventDefault()

    if (!!initialValues.lsBranches) {
      const changedBranches = this.diffList(values.lsBranches, initialValues.lsBranches)

      if (changedBranches) {
        this.handleOpenJustificationModal('lsBranch')
      } else {
        this.handleSubmit(dsToken)
      }
    } else {
      this.handleSubmit(dsToken)
    }
  }

  handleOpenJustificationModal = (field) => {
    const { openJustificationModal, values } = this.props

    openJustificationModal(
      { key: field, value: field },
      this.handleJustification,
      values.servcPrvdrAgntId,
    )
  }

  handleJustification = (token, justificationValues) => {
    if (justificationValues.hasOwnProperty('lsBranch')) {
      this.setState({ lsBranchesJustification: justificationValues.description }, () => {
        this.handleSubmit(token)
      })
    }
  }

  handleSubmit = async (token) => {
    const {
      initialValues,
      servcPrvdrAgntAbsncPerds,
      updateServiceSpecialist,
      values,
      fields,
      auth: { setupParameters },
    } = this.props

    const { lsBranchesJustification, lsPhones } = this.state

    Object.entries(fields).map(([key, value]) => (values[key] = value.input.value))
    this.setState({
      lsPhones: [
        { valMeioComunicacao: values.phone1 },
        { valMeioComunicacao: values.phone2 },
        { valMeioComunicacao: values.phone2 },
      ],
    })

    const lsBranches = values.lsBranches
      ? values.lsBranches.map((branch) => branch.value) || []
      : []

    const params = {
      ...initialValues,
      ...values,
      datUltimaSincronizacao: '',
      lsBranchesJustification,
      lsPhones: lsPhones.filter((phone) => phone && phone.valMeioComunicacao),
      servcPrvdrAgntAbsncPerds,
      lsBranches,
    }

    if (params.filesToAdd && params.filesToAdd.length > 0) {
      const files = params.filesToAdd.map((fileToAdd) => ({
        file: fileToAdd.src.split(',').pop(),
        name: fileToAdd.descAnexo,
      }))

      params.filesToAdd = {
        atchmtDocmntTyp: params.filesToAdd[0].type,
        files,
      }
    }

    const response = await updateServiceSpecialist(token, params, {
      redirectTo: null,
      showToastSuccess: false,
    })

    if (response && response.servcPrvdrAgntId) {
      store.dispatch(showSpinner())
      const servcOrdSpecialtiesPriorityEnabled = setupParameters.servcOrdSpecialtiesPriorityEnabled
      if (servcOrdSpecialtiesPriorityEnabled) {
        const specialitiesP1 = this.SpecialitiesSelectP1Ref.current.getSpecialities()
        const specialitiesP2 = this.SpecialitiesSelectP2Ref.current.getSpecialities()
        const specialitiesList = []
        specialitiesList.push(...specialitiesP1)
        specialitiesList.push(...specialitiesP2)
        updateProviderSpecialistSpecialitiesWithPriority(
          response.servcPrvdrAgntId,
          specialitiesList,
        )
      } else {
        await this.SpecialitiesSelectP1Ref.current.save(response.servcPrvdrAgntId)
      }
      store.dispatch(hideSpinner())

      renderToastSuccessMsg(i18next.t('company.info.change.stores.success'))
      this.handleCancel()
    }
  }

  userChange = (event) => {
    const { errors } = this.props
    const { value } = event.target
    const isValid = value ? this.userRegex.test(value) : true

    this.setState({
      errors: {
        ...errors,
        codUserEspecialistaServico: isValid ? '' : 'serviceSpecialists.invalidUsernameChar',
      },
    })

    this.handleChange(event)
  }

  handleChangeProvider = (option) => {
    // eslint-disable-next-line no-param-reassign
    option = option || { value: null, label: '' }
    const { value, label } = option
    const { setValueToField, fields, resetError } = this.props

    const oldProviderId = this.fields.idPrestadorServico.input.value

    setValueToField('nomePrestadorServico', label)
    setValueToField('idPrestadorServico', value)

    fields.nomePrestadorServico.input.value = label
    fields.idPrestadorServico.input.value = value

    resetError(['nomePrestadorServico', 'idPrestadorServico', 'lsBranches'])

    if (oldProviderId !== value) {
      fields.lsBranches.input.value = []
      this.refChildBranchSelect && this.refChildBranchSelect.findStores(value)
    }
  }

  handleServiceProviderAutocomplete = (value) => {
    const { getServiceProviders } = this.props

    const newFilters = { servcPrvdrTrdNm: value }

    getServiceProviders(newFilters)
  }

  renderEmptyNotification = () => {
    const { t } = this.props

    return (
      <Notification
        className='notification-warning margin-top'
        message={t('servcPrvdrAgntAbsncPerds-list.not-found')}
      />
    )
  }

  inativeActiveCallback = (token, args, onUpdateSpecialist, OnToastError) => {
    const { inactiveSpecialist, setValueToField } = this.props
    this.setState({ actvServcPrvdrAgntInd: args.actvServcPrvdrAgntInd }, () => {
      inactiveSpecialist(
        token,
        args,
        () => {
          onUpdateSpecialist(args.actvServcPrvdrAgntInd)
          setValueToField('indEspecialistaServicoAtivo', args.actvServcPrvdrAgntInd)
        },
        (error) => {
          OnToastError(error)
        },
      )
    })
  }

  handleCancel = () => {
    const { history } = this.props

    const hasHistoryFromState = history.location.state && history.location.state.from

    if (hasHistoryFromState) {
      history.push(history.location.state.from)
    } else {
      history.push('/installers')
    }
  }

  render() {
    const {
      t,
      serviceProviders,
      toggleServiceSpecialistModal,
      disabled,
      dispatch,
      servcPrvdrAgntAbsncPerds,
      auth: { user, setupParameters },
      values,
      openJustificationModal,
      initialValues,
      index,
      blockPassword,
    } = this.props

    const { fields } = this

    const { actvServcPrvdrAgnt, lsPhones: phoneFields, errors } = this.state

    const phone1 = phoneFields[0] !== undefined ? phoneFields[0].valMeioComunicacao : ''

    const optional = t('editForm.optional')

    const editOrView = !!index

    const isActive = initialValues.indEspecialistaServicoAtivo
      ? initialValues.indEspecialistaServicoAtivo === '1'
      : actvServcPrvdrAgnt === '1'

    const servcOrdSpecialtiesPriorityEnabled = setupParameters.servcOrdSpecialtiesPriorityEnabled

    return (
      <form className='container-fluid margin-top' onSubmit={this.handleBeforeSubmit}>
        <Row>
          <Col lg={12} md={12} xs={12}>
            <MozaicHeading size='m' className='no-margin-top margin-bottom-small margin-left'>
              {t('serviceSpecialists.register')}
            </MozaicHeading>
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} xs={12} className='margin-top margin-left'>
            <MozaicText>{t('serviceSpecialists.personalData')}</MozaicText>
          </Col>
        </Row>

        <Input id='idEspecialistaServico' {...fields.idEspecialistaServico.input} type='hidden' />

        <Row className='margin-bottom-small'>
          <Col lg={12} md={12} xs={12}>
            <Col lg={3} md={3} xs={12}>
              <InputButton
                id='indEspecialistaServicoAtivo'
                name='indEspecialistaServicoAtivo'
                value={fields.indEspecialistaServicoAtivo.input}
                onChange={
                  _.isEmpty(initialValues.indEspecialistaServicoAtivo)
                    ? this.handleChange
                    : () =>
                        openJustificationModal(
                          {
                            key: 'actvServcPrvdrAgntInd',
                            value: initialValues.indEspecialistaServicoAtivo === '1' ? '0' : '1',
                          },
                          this.inativeActiveCallback,
                          index,
                        )
                }
                className='full-width'
                labelAdditionalClass='full-width'
                text={t('serviceSpecialists.indEspecialistaServicoAtivo') + optional}
                checked={isActive}
                disabled={disabled}
              />
            </Col>

            <Col lg={3} md={3} xs={12}>
              <Field
                {...fields.numCpfEspecialistaServico}
                error={t(fields.numCpfEspecialistaServico.error)}
                validate
              >
                <Input
                  id='numCpfEspecialistaServico'
                  {...fields.numCpfEspecialistaServico.input}
                  onChange={this.handleChange}
                  label={t('serviceSpecialists.numCpfEspecialistaServico')}
                  mask={getCPFMask(t)}
                  disabled={disabled}
                  allowEmpty
                />
              </Field>
            </Col>

            <Col lg={3} md={3} sm={3} xs={12}>
              <Field glyph='glyph glyph-calendar' input={false} defaultActive>
                <Input
                  name='datInclusaoReg'
                  value={
                    index
                      ? initialValues.datInclusaoReg
                        ? moment(initialValues.datInclusaoReg).format(FORMAT_SINGLE_DATE_PICKER)
                        : ''
                      : moment(Date()).format(FORMAT_SINGLE_DATE_PICKER)
                  }
                  label={t('serviceProvider.registerDate')}
                  disabled
                />
              </Field>
            </Col>
          </Col>
        </Row>

        <Row className='margin-bottom-small'>
          <Col lg={12} md={12} xs={12}>
            <Col lg={9} md={9} xs={12}>
              <Field
                {...fields.nomeEspecialistaServico}
                error={t(fields.nomeEspecialistaServico.error)}
                validate
              >
                <Input
                  id='nomeEspecialistaServico'
                  {...fields.nomeEspecialistaServico.input}
                  onChange={this.handleChange}
                  label={t('serviceSpecialists.nomeEspecialistaServico')}
                  maxLength={120}
                  disabled={disabled}
                />
              </Field>
            </Col>
            <Col lg={3} md={3} xs={12}>
              <Field
                {...fields.datNascimento}
                error={t(fields.datNascimento.error)}
                glyph='glyph glyph-calendar'
                validate
              >
                <Input
                  id='datNascimento'
                  {...fields.datNascimento.input}
                  onChange={this.handleChange}
                  label={t('serviceSpecialists.datNascimento')}
                  mask='99/99/9999'
                  allowEmpty
                  disabled={disabled}
                />
              </Field>
            </Col>
          </Col>
        </Row>

        <Row className='margin-bottom-small'>
          <Col lg={12} md={12} xs={12}>
            <Col lg={2} md={2} xs={12}>
              <Field
                {...fields.numRgEspecialistaServico}
                error={t(fields.numRgEspecialistaServico.error)}
                validate
              >
                <Input
                  id='numRgEspecialistaServico'
                  {...fields.numRgEspecialistaServico.input}
                  onChange={this.handleChange}
                  label={t('serviceSpecialists.numRgEspecialistaServico') + optional}
                  maxLength={20}
                  disabled={disabled}
                />
              </Field>
            </Col>
            <Col lg={2} md={2} xs={12}>
              <Field
                {...fields.natrlPrsnAddlTaxIdDigtNr}
                error={t(fields.natrlPrsnAddlTaxIdDigtNr.error)}
                validate
              >
                <Input
                  id='natrlPrsnAddlTaxIdDigtNr'
                  {...fields.natrlPrsnAddlTaxIdDigtNr.input}
                  onChange={this.handleChange}
                  label={t('serviceSpecialists.natrlPrsnAddlTaxIdDigtNr') + optional}
                  mask='SSS'
                  disabled={disabled}
                />
              </Field>
            </Col>
            <Col lg={2} md={2} xs={12}>
              <Field
                {...fields.natrlPrsnAddlTaxIdIssurCd}
                error={t(fields.natrlPrsnAddlTaxIdIssurCd.error)}
                validate
              >
                <Input
                  id='natrlPrsnAddlTaxIdIssurCd'
                  {...fields.natrlPrsnAddlTaxIdIssurCd.input}
                  onChange={this.handleChange}
                  label={t('serviceSpecialists.natrlPrsnAddlTaxIdIssurCd')}
                  mask='AAAAA'
                  allowEmpty
                  disabled={disabled}
                />
              </Field>
            </Col>
            <Col md={3} sm={3} xs={12}>
              <Field
                {...fields.natrlPrsnAddlTaxIdIssurStCd}
                error={t(fields.natrlPrsnAddlTaxIdIssurStCd.error)}
                validate
              >
                <StatesSelect
                  {...fields.natrlPrsnAddlTaxIdIssurStCd.input}
                  id='natrlPrsnAddlTaxIdIssurStCd'
                  name='natrlPrsnAddlTaxIdIssurStCd'
                  label='serviceSpecialists.natrlPrsnAddlTaxIdIssurStCd'
                  placeholder={t('serviceSpecialists.natrlPrsnAddlTaxIdIssurStCd')}
                  onChange={(selected) => {
                    this.handleSelectChange(selected, 'natrlPrsnAddlTaxIdIssurStCd')
                  }}
                  optional
                  disabled={disabled}
                />
              </Field>
            </Col>
            <Col lg={3} md={3} xs={12}>
              <Field
                {...fields.natrlPrsnAddlTaxIdIssurDt}
                error={t(fields.natrlPrsnAddlTaxIdIssurDt.error)}
                glyph='glyph glyph-calendar'
                validate
              >
                <Input
                  id='natrlPrsnAddlTaxIdIssurDt'
                  {...fields.natrlPrsnAddlTaxIdIssurDt.input}
                  onChange={this.handleChange}
                  label={t('serviceSpecialists.natrlPrsnAddlTaxIdIssurDt')}
                  mask='99/99/9999'
                  allowEmpty
                  disabled={disabled}
                />
              </Field>
            </Col>
          </Col>
        </Row>

        <Row className='margin-bottom-small'>
          <Col lg={12} md={12} xs={12}>
            <Col lg={6} md={6} xs={12}>
              <Field
                {...fields.servcPrvdrAgntMthrNm}
                error={t(fields.servcPrvdrAgntMthrNm.error)}
                validate
              >
                <Input
                  id='servcPrvdrAgntMthrNm'
                  {...fields.servcPrvdrAgntMthrNm.input}
                  onChange={this.handleChange}
                  label={t('serviceSpecialists.servcPrvdrAgntMthrNm')}
                  maxLength={120}
                  disabled={disabled}
                />
              </Field>
            </Col>
            <Col lg={6} md={6} xs={12}>
              <Field
                {...fields.servcPrvdrAgntFthrNm}
                error={t(fields.servcPrvdrAgntFthrNm.error)}
                validate
              >
                <Input
                  id='servcPrvdrAgntFthrNm'
                  {...fields.servcPrvdrAgntFthrNm.input}
                  onChange={this.handleChange}
                  label={t('serviceSpecialists.servcPrvdrAgntFthrNm')}
                  maxLength={120}
                  disabled={disabled}
                />
              </Field>
            </Col>
          </Col>
        </Row>

        <Row className='margin-bottom-small'>
          <Col lg={12} md={12} xs={12}>
            <Col lg={4} md={4} xs={12}>
              <Field
                {...fields.codUserEspecialistaServico}
                error={t(
                  fields.codUserEspecialistaServico.error || errors.codUserEspecialistaServico,
                )}
                validate
              >
                <Input
                  id='codUserEspecialistaServico'
                  {...fields.codUserEspecialistaServico.input}
                  onChange={this.userChange}
                  label={t('serviceSpecialists.codUserEspecialistaServico')}
                  maxLength={100}
                  disabled={disabled}
                />
              </Field>
            </Col>

            <Col lg={4} md={4} xs={12}>
              <MozaicPasswordInput
                id='descSenhaEspecialistaServico'
                {...fields.descSenhaEspecialistaServico.input}
                onChange={this.handleChange}
                label={t('serviceSpecialists.descSenhaEspecialistaServico')}
                error={t(fields.descSenhaEspecialistaServico.error)}
                isDisabled={
                  (initialValues.descSenhaEspecialistaServico && blockPassword) || disabled
                }
              />
            </Col>

            <Col lg={4} md={4} xs={12}>
              <MozaicPasswordInput
                id='descSenhaEspecialistaServicoConfirm'
                {...fields.descSenhaEspecialistaServicoConfirm.input}
                onChange={this.handleChange}
                label={t('serviceSpecialists.descSenhaEspecialistaServicoConfirm')}
                error={t(fields.descSenhaEspecialistaServicoConfirm.error)}
                isDisabled={
                  (initialValues.descSenhaEspecialistaServico && blockPassword) || disabled
                }
              />
            </Col>
          </Col>
        </Row>

        <Row className='margin-bottom-small'>
          <Col lg={12} md={12} xs={12}>
            <Col lg={4} md={4} xs={12}>
              <Field
                {...fields.idPrestadorServico}
                error={t(fields.idPrestadorServico.error)}
                validate
              >
                <ServiceProviderSelect
                  t={t}
                  {...fields.idPrestadorServico.input}
                  label={t('serviceSpecialists.idPrestadorServico')}
                  placeholder={t('serviceSpecialists.idPrestadorServico')}
                  id='idPrestadorServico'
                  serviceProviders={serviceProviders}
                  onInputChange={this.handleServiceProviderAutocomplete}
                  onChange={this.handleChangeProvider}
                  disabled={disabled}
                />
              </Field>
            </Col>

            <Col lg={4} md={4} xs={12}>
              <Field {...fields.idPerfilAcesso} error={t(fields.idPerfilAcesso.error)} validate>
                <ProfileSelect
                  t={t}
                  {...fields.idPerfilAcesso.input}
                  id='idPerfilAcesso'
                  name='idPerfilAcesso'
                  onChange={this.handleChange}
                  disabled={disabled}
                />
              </Field>
            </Col>

            <Col lg={4} md={4} sm={6} xs={12}>
              <Field {...fields.phone1} error={t(fields.phone1.error)} validate>
                <TelephoneInput
                  {...fields.phone1.input}
                  id='phone1'
                  name='phone1'
                  label={t('serviceProvider.phone1')}
                  country={getPhoneNumberCountry(phone1)}
                  value={phone1}
                  disabled={disabled}
                  onChange={(value) => this.handlePhoneNumberChange('phone1', value || '')}
                />
              </Field>
            </Col>
          </Col>
        </Row>

        <Row className='margin-bottom-small'>
          <Col lg={12} md={12} xs={12}>
            <Col lg={6} md={6} xs={12}>
              <Field
                {...fields.servcPrvdrAgntEmailTxt}
                error={t(fields.servcPrvdrAgntEmailTxt.error)}
                validate
              >
                <Input
                  id='servcPrvdrAgntEmailTxt'
                  {...fields.servcPrvdrAgntEmailTxt.input}
                  onChange={this.handleChange}
                  label={t('serviceOrders.servcPrvdrAgntEmailTxtEspecialista') + optional}
                  maxLength={200}
                  disabled={disabled}
                />
              </Field>
            </Col>
            <Col lg={2} md={2} xs={12}>
              <Field
                {...fields.datUltimaSincronizacao}
                error={t(fields.datUltimaSincronizacao.error)}
              >
                <Input
                  id='datUltimaSincronizacao'
                  {...fields.datUltimaSincronizacao.input}
                  label={t('serviceSpecialist.datUltimaSincronizacao')}
                  disabled
                />
              </Field>
            </Col>

            <Col lg={2} md={2} xs={6}>
              <Field {...fields.lastLognSysVerCd} error={t(fields.lastLognSysVerCd.error)}>
                <Input
                  id='lastLognSysVerCd'
                  {...fields.lastLognSysVerCd.input}
                  label={t('serviceSpecialist.lastLognSysVerCd')}
                  disabled
                />
              </Field>
            </Col>

            <Col lg={2} md={2} xs={6}>
              <Field {...fields.sysAcsUserAvgGrade} error={t(fields.sysAcsUserAvgGrade.error)}>
                <Input
                  id='sysAcsUserAvgGrade'
                  {...fields.sysAcsUserAvgGrade.input}
                  label={t('serviceSpecialist.sysAcsUserAvgGrade')}
                  disabled
                />
              </Field>
            </Col>
          </Col>
        </Row>

        <Row className='margin-bottom-small'>
          <Col lg={12} md={12} xs={12}>
            <Col lg={6} md={6} sm={6} xs={12}>
              <Field {...fields.lsBranches} error={t(fields.lsBranches.error)} validate>
                <BranchSelectMultiple
                  {...fields.lsBranches.input}
                  ref={this.childBranchSelect}
                  id='lsBranch'
                  user={user}
                  providerLinkedToAgent={setupParameters.providerLinkedAgent}
                  initialValues={initialValues}
                  dispatch={dispatch}
                  servcPrvdrId={values.idPrestadorServico}
                  t={t}
                  isDisabled={disabled}
                />
              </Field>
            </Col>

            <Col lg={6} md={6} sm={6} xs={12}>
              {!servcOrdSpecialtiesPriorityEnabled && (
                <SpecialitiesSelect
                  ref={this.SpecialitiesSelectP1Ref}
                  servcPrvdrId={fields.idPrestadorServico.input.value}
                  servcPrvdrAgntId={fields.idEspecialistaServico.input.value}
                  disabled={disabled}
                  label='specialties'
                  priority={1}
                />
              )}
            </Col>
          </Col>
        </Row>

        {servcOrdSpecialtiesPriorityEnabled && (
          <Row className='margin-bottom-small'>
            <Col lg={12} md={12} xs={12}>
              <Col lg={6} md={6} sm={6} xs={12}>
                <SpecialitiesSelect
                  ref={this.SpecialitiesSelectP2Ref}
                  servcPrvdrId={fields.idPrestadorServico.input.value}
                  servcPrvdrAgntId={fields.idEspecialistaServico.input.value}
                  disabled={disabled}
                  label='specialities.p2'
                  servcOrdSpecialtiesPriorityEnabled={servcOrdSpecialtiesPriorityEnabled}
                  priority={2}
                />
              </Col>
              <Col lg={6} md={6} sm={6} xs={12}>
                <SpecialitiesSelect
                  ref={this.SpecialitiesSelectP1Ref}
                  servcPrvdrId={fields.idPrestadorServico.input.value}
                  servcPrvdrAgntId={fields.idEspecialistaServico.input.value}
                  disabled={disabled}
                  servcOrdSpecialtiesPriorityEnabled={servcOrdSpecialtiesPriorityEnabled}
                  label='specialities.p1'
                  priority={1}
                />
              </Col>
            </Col>
          </Row>
        )}

        <Row className='margin-top-double margin-bottom-double padding-right-mobile'>
          <Col lg={6} md={5} sm={5}>
            <MozaicHeading size='m' className='no-margin-top no-margin-bottom margin-left'>
              {t('servcPrvdrAgntAbsncPerd.register')}
            </MozaicHeading>
          </Col>
        </Row>

        {isEmpty(servcPrvdrAgntAbsncPerds) ? (
          this.renderEmptyNotification()
        ) : (
          <Table servcPrvdrAgntAbsncPerds={servcPrvdrAgntAbsncPerds} />
        )}

        <Row className='margin-top-double margin-bottom padding-left-mobile padding-right-mobile'>
          <Col xs={12} md={2} className='margin-bottom'>
            <button
              className='button button-primary button-full align-center color-primary-dark'
              type='button'
              onClick={toggleServiceSpecialistModal}
            >
              {t('option.manageDocuments')}
            </button>
          </Col>

          {editOrView && (
            <Col xs={12} md={2} className='margin-bottom'>
              <Link className='button button-primary' to={`/servcPrvdrAgntTmlineEvnt?id=${index}`}>
                {t('servcPrvdrAgntTmlineEvnt.viewLog')}
              </Link>
            </Col>
          )}

          <Col mdOffset={editOrView ? (disabled ? 6 : 4) : 6} xs={12} md={2}>
            <div
              role='button'
              onClick={this.handleCancel}
              className='button-hollow button-hollow-danger button-full align-center margin-bottom'
            >
              {t('back_button_label')}
            </div>
          </Col>

          {!disabled && (
            <Col xs={12} md={2}>
              <button type='submit' className='button button-primary button-full align-center'>
                {t(editOrView ? 'save_button_label' : 'register_button_label')}
              </button>
            </Col>
          )}
        </Row>
      </form>
    )
  }
}

ServiceSpecialistEditForm.propTypes = {
  fields: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  serviceProviders: PropTypes.array,
  getServiceProviders: PropTypes.func.isRequired,
  setValueToField: PropTypes.func.isRequired,
  toggleServiceSpecialistModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  servcPrvdrAgntAbsncPerds: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  openJustificationModal: PropTypes.func.isRequired,
  updateServiceSpecialist: PropTypes.func.isRequired,
  inactiveSpecialist: PropTypes.func.isRequired,
  blockPassword: PropTypes.bool,
  history: PropTypes.object.isRequired,
  resetError: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
}

const fields = [
  'idEspecialistaServico',
  'servcPrvdrAgntId',
  'idPrestadorServico',
  'nomePrestadorServico',
  'numCpfEspecialistaServico',
  'numRgEspecialistaServico',
  'natrlPrsnAddlTaxIdDigtNr',
  'natrlPrsnAddlTaxIdIssurCd',
  'natrlPrsnAddlTaxIdIssurStCd',
  'servcPrvdrAgntEmailTxt',
  'nomeEspecialistaServico',
  'codLocalCadastroEspecialistaServico',
  'datNascimento',
  'servcPrvdrAgntMthrNm',
  'servcPrvdrAgntFthrNm',
  'descNaturalidade',
  'indTipoRegistroEspecialistaServico',
  'numRegistroEspecialistaServico',
  'codUserEspecialistaServico',
  'descSenhaEspecialistaServico',
  'descSenhaEspecialistaServicoConfirm',
  'indEspecialistaServicoAtivo',
  'idPerfilAcesso',
  'datUltimaSincronizacao',
  'servcPrvdrAgntStrtAbsncDt',
  'natrlPrsnAddlTaxIdIssurDt',
  'servcPrvdrAgntEndAbsncDt',
  'phone1',
  'phone2',
  'phone3',
  'lastLognSysVerCd',
  'averageRating',
  'servcPrvdrAgntAbsncPerdList',
  'sysAcsUserAvgGrade',
  'lsBranches',
  'identifyRegistrationOrigin',
]

export default Form({ fields })(ServiceSpecialistEditForm)

export { ServiceSpecialistEditForm }
