export default [
  {
    path: '/providers/edit',
    accessFunctions: [19],
    dynamic: true,
  },
  {
    path: '/providers/new',
    accessFunctions: [19],
  },
  {
    path: '/providers/view',
    accessFunctions: [18],
    dynamic: true,
  },
  {
    path: '/providers',
    accessFunctions: [18, 19],
    dynamic: true,
    unique: true,
  },
  {
    path: '/installers/view',
    accessFunctions: [20],
    dynamic: true,
  },
  {
    path: '/installers/edit',
    accessFunctions: [21],
    dynamic: true,
  },
  {
    path: '/installers/new',
    accessFunctions: [21],
  },
  {
    path: '/installers',
    accessFunctions: [20, 21],
    dynamic: true,
    unique: true,
  },
]
