import i18next from 'i18next'
import moment from 'moment-timezone'

import 'moment/dist/locale/es'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/it'
import 'moment/dist/locale/pl'
import 'moment/dist/locale/pt'
import 'moment/dist/locale/pt-br'

moment.tz.setDefault(moment.tz.guess())
moment.locale(navigator.language)

/* CONSTANTE PARA STATUS SIM ou NÂO */
export const YES_NO = {
  0: 'option.no',
  1: 'option.yes',
}

const longDateFormat = moment.localeData()._config.longDateFormat

/* Padrão de datas do sistema */
export const FORMAT_DATE = `${longDateFormat.L} ${longDateFormat.LTS}`
export const FORMAT_DATE_TRUNC = longDateFormat.L
export const FORMAT_DATE_TRUNC_DOT = 'DD.MM.YYYY'
export const FORMAT_SINGLE_DATE_PICKER = longDateFormat.L
export const DATE_PATTERN = '99/99/9999'

export const SHIFT_CODES = {
  M: 'service.portal.morning',
  A: 'service.portal.afternoon',
  E: 'service.portal.night',
}

export const ACTV_SERVC_PRVDR_ORD_RECV_IND_TYPES = {
  ACTIVE: '1',
  INACTIVE: '0',
}

export const ACTV_SERVC_PRVDR_IND_TYPES = {
  ACTIVE: '1',
  INACTIVE: '0',
}

/* ACCOUNT TYPES */
export const ACCOUNT_TYPES = {
  1: 'account.type.checking',
  2: 'account.type.saving',
}