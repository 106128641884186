import { useCallback, useMemo, useState } from 'react'
import i18next from 'i18next'
import {
  DataTable as MozaicDataTable,
  DataTableEmptyView,
  Flex,
  IDataTableColumn,
  Text,
} from '@mozaic-ds/react'

import { Footer } from './components/Footer'
import { ActionsRow } from './components/ActionsRow'
import { DeleteModal } from './components/DeleteModal'
import ProviderEntity from '../../../../models/Provider'
import { formatNationalOrInternationalPhoneNumber } from '../../../../helpers/InternationalPhoneNumberUtils'
import { formatCNPJ } from '../../../../helpers/masks'
import User from '../../../../core/User'
import { useServiceProvidersListV2Context } from '../../contexts'
import { ActiveRegistrationRow } from './components/ActiveRegistrationRow'
import { ServiceOrderReceiptRow } from './components/ServiceOrderReceiptRow'
import { RegistrationStatusModal } from './components/RegistrationStatusModal'
import { ReceiptServiceOrderModal } from './components/ReceiptServiceOrderModal'

const user = new User()

const FUNCTION_ACCESS_TO_EDIT = 19

/**
 * @description Renders the Service Providers List V2 datatable
 */

export function DataTable() {
  const [serviceProviderToInactive, setServiceProviderToInactive] = useState<ProviderEntity>()
  const [
    serviceProviderToUpdateReceiptServiceOrder,
    setServiceProviderToUpdateReceiptServiceOrder,
  ] = useState<ProviderEntity>()
  const [serviceProviderToDelete, setServiceProviderToDelete] = useState<ProviderEntity | null>(
    null,
  )

  const { serviceProviders, isFetchServiceProvidersLoading, isFetchServiceProvidersError } =
    useServiceProvidersListV2Context()

  const hasUserAccessToEdit =
    user.currentUser.accessFunctionList?.indexOf(FUNCTION_ACCESS_TO_EDIT) !== -1

  const findphone = (lsPhones: any) => {
    if (lsPhones) {
      for (let i = 0; i < lsPhones.length; i++) {
        if (lsPhones[i] !== undefined && lsPhones[i].idTipoMeioComunicacao === 1) {
          return lsPhones[i].valMeioComunicacao
        }
      }
    }
    return ''
  }

  const columns: Array<IDataTableColumn<ProviderEntity>> = useMemo(
    () => [
      {
        key: 'servcPrvdrFedrlTaxIdNr',
        label: i18next.t('option.servcPrvdrFedrlTaxIdNr'),
        render: ({ servcPrvdrFedrlTaxIdNr }) => (
          <div key={servcPrvdrFedrlTaxIdNr}>{formatCNPJ(servcPrvdrFedrlTaxIdNr)}</div>
        ),
        width: '13.5%',
      },
      {
        label: i18next.t('serviceProvider.servcPrvdrTrdNm'),
        key: 'servcPrvdrTrdNm',
        width: '25%',
      },
      {
        key: 'lsPhones',
        label: i18next.t('serviceProvider.phone1'),
        render: ({ servcPrvdrId, lsPhones }) => (
          <div key={servcPrvdrId} data-testid='phones'>
            {lsPhones !== undefined
              ? formatNationalOrInternationalPhoneNumber(i18next.t, findphone(lsPhones))
              : ''}
          </div>
        ),
        width: '12%',
      },
      {
        key: 'actvServcPrvdrInd',
        label: i18next.t('serviceProvider.prestadorServicoAtivo'),
        render: (serviceProvider) => (
          <ActiveRegistrationRow
            hasEditPermission={hasUserAccessToEdit}
            isEnabled
            isRegistrationActive={serviceProvider.actvServcPrvdrInd}
            onToggle={() => setServiceProviderToInactive({ ...serviceProvider })}
          />
        ),
        width: '12%',
      },
      {
        key: 'actvServcPrvdrOrdRecvInd',
        label: i18next.t('serviceProvider.actvServcPrvdrOrdRecvInd'),
        render: (serviceProvider) => (
          <ServiceOrderReceiptRow
            hasEditPermission={hasUserAccessToEdit}
            isEnabled
            isServiceOrderReceiptActive={serviceProvider.actvServcPrvdrOrdRecvInd}
            onToggle={() => setServiceProviderToUpdateReceiptServiceOrder({ ...serviceProvider })}
          />
        ),
        width: '12%',
      },
      {
        label: i18next.t('actions'),
        render: (serviceProvider) => (
          <ActionsRow
            serviceProvider={serviceProvider}
            onClickDelete={() => setServiceProviderToDelete({ ...serviceProvider })}
          />
        ),
        width: '10%',
        style: {
          textAlign: 'center',
        },
      },
    ],
    [],
  )

  const getRowKey = useCallback((row: ProviderEntity) => row.servcPrvdrId, [])

  const isEmpty = serviceProviders && !serviceProviders.length

  return (
    <div id='service-v2'>
      <MozaicDataTable<ProviderEntity>
        columns={columns}
        rows={serviceProviders || []}
        getRowKey={getRowKey}
        isPending={isFetchServiceProvidersLoading}
      >
        {isEmpty && (
          <DataTableEmptyView>
            <Flex padding='mu500'>
              <Text size='l' theme='light'>
                {i18next.t('info.table.empty')}
              </Text>
            </Flex>
          </DataTableEmptyView>
        )}
        {isFetchServiceProvidersError && (
          <DataTableEmptyView>
            <Flex padding='mu500'>
              <Text size='l' theme='danger'>
                {i18next.t('conditions.message.error')}
              </Text>
            </Flex>
          </DataTableEmptyView>
        )}
        <Footer />
        <DeleteModal serviceProvider={serviceProviderToDelete} />
        <RegistrationStatusModal serviceProvider={serviceProviderToInactive} />
        <ReceiptServiceOrderModal serviceProvider={serviceProviderToUpdateReceiptServiceOrder} />
      </MozaicDataTable>
    </div>
  )
}
