/* eslint-disable react/jsx-no-constructed-context-values */
import PropTypes from 'prop-types'
import { createContext, useContext, useMemo, useState } from 'react'

const SideNavContext = createContext({})

function SideNavProvider({ children }) {
  const [openedProfileInfo, setOpenedProfileInfo] = useState(false)
  const [openedBuCdModal, setOpenedBuCdModal] = useState(false)

  function handleCloseProfileInfo() {
    openedProfileInfo && setOpenedProfileInfo(false)
  }

  function handleCloseBuCdModal() {
    setOpenedBuCdModal(false)
  }

  function handleOpenBuCdModal() {
    setOpenedProfileInfo(false)
    setOpenedBuCdModal(true)
  }

  function handleToggleProfileInfo() {
    setOpenedProfileInfo(!openedProfileInfo)
  }


  const providerData = useMemo(
    () => ({
      openedProfileInfo,
      setOpenedProfileInfo,
      handleCloseProfileInfo,
      handleToggleProfileInfo,
      openedBuCdModal,
      handleOpenBuCdModal,
      handleCloseBuCdModal,
    }),
    [
      openedProfileInfo,
      openedBuCdModal,
    ],
  )

  return <SideNavContext.Provider value={providerData}>{children}</SideNavContext.Provider>
}

SideNavProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useSideNavContext = () => useContext(SideNavContext)

export { SideNavContext, SideNavProvider, useSideNavContext }
