/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux'

import auth from './auth'
import i18nReducer from './I18n'
import spinnerReducer from './spinner'
import menuReducer from './menu'
import paginateReducer from './pagination'

const rootReducer = combineReducers({
  auth,
  i18nReducer,
  menuReducer,
  spinnerReducer,
  paginateReducer,
})

export default rootReducer
