import i18next from 'i18next'
import { SHIFT_CODES } from '../constants/config'

export const getShiftByKey = (key) => {
  switch (key.toUpperCase()) {
    case 'M':
    case 'A':
    case 'E':
      return i18next.t(SHIFT_CODES[key])
    case '':
      return i18next.t('service.portal.all.day')
    default:
      return i18next.t(SHIFT_CODES.M)
  }
}
