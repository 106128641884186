import {
  API_INSTALA_AUTH,
  API_INSTALA,
  API_INSTALA_PROVIDER,
  API_INSTALA_I18N,
  API_INSTALA_PARAM,
  API_SERVICES_REGIONS,
} from './envs'

const endpoints = {
  URL: API_INSTALA,

  // Access
  LOGIN: `${API_INSTALA_AUTH}/public/user-validation-ldap`,
  LOGIN_PINGONE: `${API_INSTALA_AUTH}/oauth2/authorize/ping-service?portal=service`,
  LOGOUT_PINGONE: `${API_INSTALA_AUTH}/oauth2/logout`,
  GET_USER_INFO: `${API_INSTALA_AUTH}/get-user-info`,

  SET_BU_CD: `${API_INSTALA_AUTH}/user/set-bu-cd`,

  SETUP_PARAMETERS: `${API_INSTALA_PARAM}/public/find-setup-parameter`,

  // Access Profiles
  PROFILE_FIND: `${API_INSTALA_AUTH}/perfilAcesso/list`,

  SYS_ACS_USER_GET: `${API_INSTALA_AUTH}/sysAcsUser/users/:id`,

  // Stores
  FIND_STORES: `${API_INSTALA}/branch/list`,
  FIND_STORES_ALL: `${API_INSTALA}/branch/list-all`,

  // States
  FIND_STATES: `${API_INSTALA}/state/list`,
  FIND_MACRO_REGION_STATES: `${API_INSTALA_PROVIDER}/public/provider/states`,

  // AmazonAws
  AMAZON_AWS_DOWNLOAD_FILE: `${API_INSTALA}/amazon/downloadFile`,
  AMAZON_AWS_GET_FILE: `${API_INSTALA}/amazon/file`,

  // Service Providers
  FIND_SERVICE_PROVIDERS_AUTOCOMPLETE: `${API_INSTALA_PROVIDER}/service-provider/listAutocomplete`,
  FIND_SERVICE_PROVIDERS_MANUAL_DISTRIBUTION: `${API_INSTALA_PROVIDER}/v1/provider:manualDistribution`,
  SERVICE_PROVIDER_FIND: `${API_INSTALA_PROVIDER}/service-provider/list-filters`,
  SERVICE_PROVIDER_SAVE: `${API_INSTALA_PROVIDER}/service-provider/saveAll`,
  DELETE_SERVICE_PROVIDER: `${API_INSTALA_PROVIDER}/service-provider/delete`,
  SERVICE_PROVIDER_FILES: `${API_INSTALA_PROVIDER}/service-provider/attachment`,
  SERVICE_PROVIDER_INACTIVE: `${API_INSTALA_PROVIDER}/service-provider/inactive`,
  SERVICE_PROVIDER_SET_ACTV_SERVC_PRVDR_ORD_RECV_IND: `${API_INSTALA_PROVIDER}/service-provider/setActvServcPrvdrOrdRecvInd`,
  FIND_SERVICE_PROVIDERS_NAME_AUTOCOMPLETE: `${API_INSTALA_PROVIDER}/service-provider/autocompleteProvider`,
  SERVICE_PROVIDER_POSTAL_CODE: `${API_INSTALA_PROVIDER}/service-provider/postalCode`,

  // Service Provider Macro Regionality
  PROVIDER_ZONES: `${API_INSTALA_PROVIDER}/provider/{servcPrvdrId}/region`,
  FIND_REGIONS: `${API_SERVICES_REGIONS}/regions/v1/region`,
  FIND_MICRO_REGIONS: `${API_SERVICES_REGIONS}/regions/v1/micro-region`,

  // Service Provider/Specialist Specialities
  UPDATE_PROVIDER_SPECIALITIES: `${API_INSTALA_PROVIDER}/service-provider/updateSpecialities`,
  FIND_PROVIDER_SPECIALITIES_IDS: `${API_INSTALA_PROVIDER}/service-provider/listSpecialitiesIds`,
  UPDATE_SERVICE_SPECIALIST_SPECIALITIES: `${API_INSTALA_PROVIDER}/servcPrvdr/updateServcSpecialistSpecialities`,
  FIND_SERVICE_SPECIALIST_SPECIALITIES_IDS: `${API_INSTALA_PROVIDER}/servcPrvdr/listSpecialitiesIds`,
  FIND_SERVICE_SPECIALIST_SPECIALITIES: `${API_INSTALA_PROVIDER}/v1/peoples/##servcPrvdrAgntId##?relations=specialities&fields=servcPrvdrAgntId`,

  // Service Provider Plant
  FIND_PROVIDER_STORES: `${API_INSTALA_PROVIDER}/service-provider-plant/list`,

  // Service Specialists
  FIND_SERVICE_SPECIALIST_LIST: `${API_INSTALA_PROVIDER}/servcPrvdr/v2/list-filters`,
  FIND_WORKER_MANUAL_DISTRIBUTION: `${API_INSTALA_PROVIDER}/v1/worker:manualDistribution`,
  FIND_SERVICE_SPECIALIST: `${API_INSTALA_PROVIDER}/servcPrvdr/v2/find-agent`,
  REMOVE_SERVICE_SPECIALIST: `${API_INSTALA_PROVIDER}/service-specialist/deleteLogic`,
  DELETE_SERVICE_SPECIALIST: `${API_INSTALA_PROVIDER}/servcPrvdr/delete`,
  INACTIVE_SERVICE_SPECIALIST: `${API_INSTALA_PROVIDER}/servcPrvdr/v2/inactive`,
  SAVE_SERVICE_SPECIALIST: `${API_INSTALA_PROVIDER}/service-specialist/save`,
  SERVICE_SPECIALIST_FILES: `${API_INSTALA_PROVIDER}/servcPrvdrAgntAtchmnt/attachment`,
  FIND_SERVICE_SPECIALIST_NAME_AUTOCOMPLETE: `${API_INSTALA_PROVIDER}/servcPrvdr/autocomplete`,
  SEND_SMS_APP_SPECIALIST: `${API_INSTALA_PROVIDER}/servcPrvdr/v2/sendConfirmationSmsSpecialist`,

  // Report
  SERVICE_SPECIALIST_REPORT_CSV: `${API_INSTALA_PROVIDER}/servcPrvdr/v2/report/csv`,
  SERVICE_PROVIDER_REPORT_CSV: `${API_INSTALA_PROVIDER}/service-provider/report/csv`,

  // Document Types
  FIND_DOCUMENT_TYPES_LIST: `${API_INSTALA_PROVIDER}/documentType/list`,

  // ServcPrvdrAgntAbsncPerd
  SERVC_PRVDR_AGNT_ABSNC_PERD_FIND: `${API_INSTALA_PROVIDER}/availability/findFilter`,

  // Banks
  BANK_FIND: `${API_INSTALA_PROVIDER}/bank/list`,

  // Provider log
  FETCH_SERVC_PRVDR_TMLINE_EVNT: `${API_INSTALA_PROVIDER}/servcPrvdrTmlineEvnt/customFindFilter`,

  // Craftsman log
  FETCH_SERVC_PRVDR_AGNT_TMLINE_EVNT: `${API_INSTALA_PROVIDER}/servcPrvdrAgntTmlineEvnt/customFindFilter`,

  // Async Report
  ENABLE_NOTIFIER_PROVIDER: `${API_INSTALA_PROVIDER}/public/sse-emiter/enable-notifier`,

  // Translation
  LOCALES_LIST: `${API_INSTALA_I18N}/public/locales/list`,

  FETCH_CORES_BY_USER: `${API_INSTALA}/servc-core/byUser`,

  // Specialities
  FETCH_SPECIALITIES: `${API_INSTALA}/specialities`,
}

export default endpoints
