import { ReactNode, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import classNames from 'classnames'

import SideBar from './components/LeftSideBar'
import SideNav from './components/TopSideNav'
import ProfileInfo from './components/TopSideNav/ProfileModal'
import BuCdModal from './components/TopSideNav/BuCdModal'

import { InstallerProvider } from '../contexts/installer'
import { SideNavProvider } from '../contexts/sidenav'
import { ProviderProvider } from '../contexts/provider'
import { Switch } from 'react-router'
import generateMenuItems from '../constants/generateMenuItems'

type PageLayoutType = {
  children: ReactNode
  currentRoute: string
}

function PageLayout(props: PageLayoutType) {
  const { children, currentRoute } = props
  const [items, setItems] = useState(generateMenuItems())

  const isLeftSideBarOpen = useSelector((state: any) => state.menuReducer.isOpen)

  const classes = classNames({
    'page-content': true,
    'page-content--left-side-bar-open': isLeftSideBarOpen,
  })

  return (
    <div id='page-container'>
      <SideBar items={items} setItems={setItems} />

      <div className={classes} id='page-content'>
        <SideNavProvider>
          <SideNav />
          <ProfileInfo />
          <BuCdModal />

          <InstallerProvider>
            <ProviderProvider>
              <Switch>{children}</Switch>
            </ProviderProvider>
          </InstallerProvider>
        </SideNavProvider>
      </div>
    </div>
  )
}

const mapStateToProps = ({ menuReducer }: any) => ({
  currentRoute: menuReducer.currentRoute,
})

export default connect(mapStateToProps, null)(PageLayout)
