import i18next from 'i18next'
import User from '../core/User'

import InstallersCPF from '../assets/icons/installersCPF.svg'
import ProvidersCNPJ from '../assets/icons/providersCNPJ.svg'
import store from '../redux/store'

const user = new User().currentUser

const generateMenuItems = () => {
  const parameters = store.getState().auth.setupParameters
  const hasAccessFunction = (accessFunction) => {
    if (user?.accessFunctionList) {
      const accessFunctionList = Array.isArray(accessFunction) ? accessFunction : [accessFunction]

      return accessFunctionList?.some((access) =>
        user?.accessFunctionList?.includes(parseInt(access, 10)),
      )
    }
    return false
  }

  return [
    {
      label: i18next.t('lmi.serviceProviders'),
      url: '/providers',
      queryParams: '?actvServcPrvdrInd=1',
      icon: ProvidersCNPJ,
      render: hasAccessFunction([18, 19]),
    },
    {
      label: i18next.t('lmi.serviceSpecialists'),
      url: '/installers',
      queryParams: '?active=1&receivingOrder=1',
      icon: InstallersCPF,
      render: hasAccessFunction([20, 21]),
    },
  ]
}

export default generateMenuItems
